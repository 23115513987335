import React, { useState } from 'react';
import variables from '../../../redux/variables';
import setCookies from '../../../setCookie/setCookie';
import { v4 as uuid } from 'uuid';
import { any } from 'prop-types';

const smartAutocompleteSelection = (smart, filter, update, setUpdate, autocompleteResult) => {
  let clickedOrder = autocompleteResult.smartAggregations.findIndex((f) => f.order === smart.order);

  let index = -1;
  index = variables.searchCallVariables.aggregations.findIndex(
    (facet) => facet.type === autocompleteResult.smartAggregations[clickedOrder].key
  );

  if (smart.key != 'post_time') {
    switch (filter.selected) {
      case true:
        filter.selected = false;
        switch (
          variables.searchCallVariables.aggregations[index] &&
          variables.searchCallVariables.aggregations[index].type == filter.parent &&
          variables.searchCallVariables.aggregations[index].filter.length > 1
        ) {
          case true:
            let moreFilters = variables.searchCallVariables.aggregations[index].filter.filter(
              (remove) => remove != filter.Contentname
            );
            variables.searchCallVariables.aggregations[index].filter = moreFilters;
            break;
          case false:
            let singlefilters = variables.searchCallVariables.aggregations.filter(
              (remove) => remove.type != filter.parent
            );
            variables.searchCallVariables.aggregations = singlefilters;
        }
        break;
      case false:
        filter.selected = true;
        switch (
          variables.searchCallVariables.aggregations[index] &&
          variables.searchCallVariables.aggregations[index].type == filter.parent &&
          !variables.searchCallVariables.aggregations[index].filter.includes(filter.Contentname)
        ) {
          case true:
            variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
            break;
          case false:
            variables.searchCallVariables.aggregations.push({
              type: filter.parent,
              filter: [filter.Contentname]
            });
            break;
        }
    }
  } else if (
    (variables.searchCallVariables.aggregations[index].type == 'post_time' ||
      variables.searchCallVariables.aggregations[index].type == 'CreatedDate') &&
    !filter.selected
  ) {
    filter.selected = true;
    smart.values.forEach((cdate) => {
      cdate.selected = cdate.Contentname == filter.Contentname;
    });
    variables.searchCallVariables.aggregations[index].filter = [];
    variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
  }

  setCookies.setSmartFacetOff();

  // In order to update the render
  setUpdate(!update);
};

const AutoLearningSuggestion = (props) => {
  let autocompleteResult = props.autocompleteResult;
  const [update, setUpdate] = useState(false);

  if (variables.searchCallVariables.smartFacets) {
    variables.searchCallVariables.clonedAggregations = JSON.parse(
      JSON.stringify(variables.searchCallVariables.aggregations)
    );
  }

  variables.autocompleteCallVariables.smartFacetsClicked = true;

  return (
    <>
      {autocompleteResult &&
        autocompleteResult.smartAggregations &&
        autocompleteResult.smartAggregations.length != 0 &&
        autocompleteResult.smartAggregations.map((item, index) => (
          <div key={item.key}>
            <>
              {item.key && item.values.length != 0 && (
                <div
                  data-key={index}
                  data-test-id={`product-suggestions-${item.label}`}
                  className="su__product-suggestion"
                >
                  <div className="su__product-sugg-iner su__flex-vcenter su__flex-wrap">
                    <div
                      data-test-id={`product-suggestion-title-${item.label}`}
                      className="su__product-sugg-title su__mb-3 su__f-bold su__font-13"
                    >
                      {item.label}
                    </div>
                  </div>
                  <div className="su__d-flex">
                    <svg
                      className="su__product-sugg-icon"
                      width="25"
                      height="21.948"
                      viewBox="0 0 29.935 23.948"
                    >
                      <path
                        className="su__fill-red"
                        d="M5.987,16.464H1.5a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,5.987,16.464ZM4.864,12.722h8.98v2.245H16.09V12.722h8.98v2.245h2.245V12.274a1.8,1.8,0,0,0-1.8-1.8H16.09V7.484h1.871a1.5,1.5,0,0,0,1.5-1.5V1.5a1.5,1.5,0,0,0-1.5-1.5H11.974a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h1.871v2.993H4.416a1.8,1.8,0,0,0-1.8,1.8v2.694H4.864Zm12.348,3.742h-4.49a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,17.212,16.464Zm11.226,0h-4.49a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,28.438,16.464Z"
                      />
                    </svg>
                    <div className="customProductSpacing su__flex-vcenter su__flex-wrap su__ml-3">
                      {item.key &&
                        item.values.length != 0 &&
                        item.values.slice(0, 3).map((product, i) => {
                          return (
                            <div
                              key={uuid()}
                              data-test-id={`product-suggestion-category-${product.Contentname}-${i}`}
                              className={
                                product.selected
                                  ? 'su__product-sugg-category su__cursor su__product-active su__font-13'
                                  : 'su__product-sugg-category su__font-13 su__cursor'
                              }
                              onClick={() => {
                                smartAutocompleteSelection(
                                  item,
                                  product,
                                  update,
                                  setUpdate,
                                  autocompleteResult
                                );
                              }}
                            >
                              {product.Contentname}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        ))}
    </>
  );
};

AutoLearningSuggestion.propTypes = {
  autocompleteResult: any
};

AutoLearningSuggestion.defaultProps = {
  autocompleteResult: {
    smartAggregations: []
  }
};

export default AutoLearningSuggestion;
