import variables from '../redux/variables';

const setCookies = {
  setSmartFacetOff: () => {
    try {
      variables.searchCallVariables.smartFacets = false;
      variables.autocompleteCallVariables.smartFacets = false;
      document.cookie = 'smartFacets=false; expires=Thu, 01 Jan 9999 00:00:00 UTC; path=/;';
    } catch (err) {
      console.log(err);
    }
  }
};

export default setCookies;
