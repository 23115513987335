import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { recommendations } from './ducks';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { recomendationsUrlReq } from './searchClientTypes';

const recommendationsEpic = (action$) =>
  action$.pipe(
    ofType(recommendations.recommendationsStart().type),
    mergeMap(async ({ data }) => {
      let paramsUrlReq = await recomendationsUrlReq(variables.searchClientType, data);
      try {
        const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const resp = response;
        const result = JSON.parse((await resp.text()).trim());
        return recommendations.recommendationsSuccess(result);
      } catch (err) {
        return recommendations.recommendationsFail(err);
      }
    })
  );

export default recommendationsEpic;
