export default {
  SearchCrossIcon: '#757575',
  SearchIcon: '#757575',
  AdvanceSearchIconFill: '#919bb0',
  AdvanceSearchEnabledIconFill: '#ffffff',
  AdvanceSearchCrossIcon: '#000000',
  AutoCompleteClearIcon: '#757575',
  AutoCompleteSearchIcon: '#333',
  AutoTunesvgFill: '#8b8b8b',
  boostedSvgFill: 'none',
  SaveBookmarksIcon: '#535355',
  SaveBookmarksCrossIcon: '#fff',
  ListBookmarksEmptyIcon: '#919bb0',
  ListBookMarksNotEmptyIcon: '#1870D4',
  ListBookmarksInModalIcon: '#fff',
  ListBookmarksCrossIcon: '#fff',
  ToggleViewGridIcon: '#535355',
  SettingsGearIcon: '#fff',
  SettingseditLayoutIcon: '#ffffff',
  SettingsSearchTipIcon: '#FF9800',
  FilterListFacetSearchIcon: '#094F9F',
  FilterListFacetSearchCloseIcon: '#094F9F',
  FacetPreferenceCrossIcon: '#333',
  FacetPreferenceShow_HideEye: '#292929',
  FacetPreferenceSaveBookmark: '#0082cacc',
  mobileFacetsCloseIcon: '#fff',
  FeaturedSnippetThumbsup_down: '#3E384A',
  FeaturedSnippetSvgFill: '#000000',
  FeatureSnippetSvgPlayGrey: '#CFCFCF',
  ScrollToTopIcon: '#fff',
  KnowledgeGraphThumbsup_down: '#fff',
  SearchResultFeedbackCloseIcon: '#000000',
  SearchResultFeedbackViewMore: '#919bb0',
  PreviewIconFill: '#57575c',
  SolvedsvgFill: '#5bb543',
  UnderConstructionsvgFill: '#008fd3',
  StickyFacetsSvgFill: '#919bb0',
  autolearn_svg_fill: '#58585A',
  search_tip_svg_fill: '#58585A',
  settings_svg_fill: '#58585A',
  saved_result_fill: '#1770D4'
};
