/* global client */
import { tabIndexes } from 'constants/a11y';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import variables from 'redux/variables';

const AutoCompleteResultTitle = (props) => {
  try {
    let { item, index, resultOpenNewTab, urlOpensInNewTab } = props;
    let autocompleteResult = useSelector((state) => state.autocomplete);
    autocompleteResult.result.hits.forEach((item) => {
      if (
        variables.searchClientType == 16 &&
        item.objName == 'tickets' &&
        urlOpensInNewTab === true
      ) {
        item.allowlinkopennewtab = true;
      } else if (
        variables.searchClientType == 16 &&
        item.objName == 'tickets' &&
        urlOpensInNewTab === false
      ) {
        item.allowlinkopennewtab = false;
      } else {
        item.allowlinkopennewtab = true;
      }
    });
    /**
     * This function gets called when clicked on title and it open the ticket in subtab by using zendesk api
     * @param {*} item
     * @param {*} index
     */
    const ticketClicked = (item, index) => {
      client.invoke('routeTo', 'ticket', item._id);
      resultOpenNewTab(item, index);
    };
    return (
      <Fragment>
        {item.allowlinkopennewtab === true ? (
          <a
            tabIndex={tabIndexes.tabIndex_minus_1}
            className="su__text-decoration su__text-black su__text-truncate su__font-14 su__font-bold"
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => resultOpenNewTab(item, index)}
          >
            <span className="su__suggestion-title su__font-13 su__text-black su__f-regular" name={item.highlight.TitleToDisplay[0] || item.href} >{item.highlight.TitleToDisplayString[0] || item.href}</span>
          </a>
        ) : (
          <a
            tabIndex={tabIndexes.tabIndex_minus_1}
            className="su__text-decoration su__text-black su__text-truncate su__font-14 su__font-bold "
            rel="noopener noreferrer"
            onClick={() => ticketClicked(item, index)}
          >
            <span
              className="su__suggestion-title su__font-13 su__text-black su__f-regular su__cursor"
              name={item.highlight.TitleToDisplay[0] || item.href}
              dangerouslySetInnerHTML={{
                __html: item.highlight.TitleToDisplay[0] || item.href.replace(/ /g, '\u00a0')
              }}
            ></span>
          </a>
        )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in AutoCompleteResultTitle component', e);
    return <div></div>;
  }
};

export default React.memo(AutoCompleteResultTitle);
