// import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { advertisementSearch } from './ducks';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

const advertisementEpic = (action$) =>
  action$.pipe(
    ofType(advertisementSearch.advStart().type),
    mergeMap(async () => {
      let url = variables.searchClientProps.instanceName + variables.searchClientProps.adEndpoint;
      let searchString = variables.searchCallVariables.searchString;
      try {
        const res = await fetch(url + searchString);
        const result = await res.json();
        return advertisementSearch.advSuccess(result);
      } catch (err) {
        return advertisementSearch.advFail(err);
      }
    })
  );

export default advertisementEpic;
