import variables from '../redux/variables';

const searchResponseFormatter = (searchResult) => {
  /** Theme Setting  */
  let a = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
  if (a && a.hideObject) {
    variables.toggleDisplayKeys = [...a.hideObject];
  }

  variables.searchCallVariables.showMoreSummary = searchResult.searchClientSettings?.showMore;
  variables.searchCallVariables.minSummaryLength =
    searchResult.searchClientSettings?.minSummaryLength;
  variables.searchCallVariables.showContentTag = searchResult.searchClientSettings?.contentTag;
  variables.autocompleteCallVariables.resultsPerPage = Number(
    searchResult.searchClientSettings?.autoComplete
  );

  return {
    viewedResult: searchResult.searchClientSettings?.ViewedResults == 1,
    metaStatus: searchResult.metaGraph && Object.keys(searchResult.metaGraph).length > 0,
    relatedTilesStatus: searchResult.relatedTiles && searchResult.relatedTiles.length > 0,
    featuredSnippetResult:
      searchResult.featuredSnippetResult &&
      Object.keys(searchResult.featuredSnippetResult).length > 0,
    didYouMean:
      searchResult.suggest &&
      searchResult.suggest.simple_phrase[0]?.options &&
      searchResult.suggest.simple_phrase[0].options.length,
    loadMoreResults: searchResult.searchClientSettings?.moreResultButton == 1,
    navigatePagination: searchResult.searchClientSettings?.pageNoButton == 1,
    scrollPagination: searchResult.searchClientSettings?.scrollPagination == 1,
    advertisements: searchResult.searchClientSettings?.advertisements == 1,
    recommendation: searchResult.searchClientSettings?.recommendations == 1,
    similarSearches: searchResult.searchClientSettings?.similarSearch == 1,
    languageManager: searchResult.searchClientSettings?.languageManager == 1,
    allContentHideFacet: searchResult.searchClientSettings?.hideAllContentSources == 1,
    noResult: searchResult.result && searchResult.result.hits.length == 0,
    hiddenFacet:
      searchResult.searchClientSettings?.hiddenFacet &&
      searchResult.searchClientSettings?.hiddenFacet.length > 0,
    searchPageFeedback:
      searchResult.searchClientSettings?.userFeedbackEnabled &&
      searchResult.searchClientSettings?.userFeedbackEnabled.searchExp,
    searchResultFeedback:
      searchResult.searchClientSettings?.userFeedbackEnabled &&
      searchResult.searchClientSettings?.userFeedbackEnabled.conversionExp
  };
};

/**
 * htmlSanitizer - sanitizes dangerously set HTML
 * @param {string} _dangerousHTML - dangerous HTML
 * @returns {string} sanitized HTML
 */
export const htmlSanitizer = (_dangerousHTML) =>
  _dangerousHTML
    .replace(/[`~!@$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, (match) => '&#' + match.charCodeAt() + ';')
    .replace(/(?:\r\n|\r|\n|\t)/g, ' ') || '';

export default searchResponseFormatter;
