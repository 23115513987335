import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import variables from '../../../redux/variables';
import IconColors from '../../../IconColors';
const AutoCompleteClearIcon = (props) => {
  try {
    let { showClearIcons, setShowAutoCompleteResult, setShowClearIcons, autocompleteSearchbox } =
      props;

    const ClearIcon = () => {
      autocompleteSearchbox.value = '';
      setShowClearIcons(false);
      setShowAutoCompleteResult(false);
      variables.searchCallVariables.from = 0;
      variables.searchCallVariables.pageNo = 1;
      variables.autocompleteCallVariables.searchString = '';
    };

    return (
      (showClearIcons || variables.searchCallVariables.searchString) && (
        <span
          className="su__input-close su__mr-4 su__animate-zoom su__position-absolute su__cursor"
          onClick={ClearIcon}
        >
          <Icons IconName="Close" width="12" height="12" color={IconColors.AutoCompleteClearIcon} />
        </span>
      )
    );
  } catch (e) {
    console.log('Error in clear searchbox Icon component', e);
    return <div></div>;
  }
};

export default React.memo(AutoCompleteClearIcon);
