import createReducer from './createReducer';
import createAction from './createAction';
import variables from './variables';

export const initialState = {
  inProgress: false,
  error: '',
  searchResult: [],
  updatedSearchResult: [],
  autocomplete: [],
  recommendationsResults: [],
  adHtml: '',
  pageRatingResult: {},
  facetPreferenceResult: []
};

let val;
let filterSearch;
let start = 0;
export const search = {
  start: (queryPassed) => createAction('SEARCH_START', { queryPassed }),
  fail: (error) => createAction('SEARCH_ERROR', { error }),
  success: (queryPassed) => createAction('SEARCH_SUCCESS', { queryPassed })
};

export const autocomplete = {
  start: (queryPassed) => createAction('AUTOCOMPLETE_START', { queryPassed }),
  fail: (error) => createAction('AUTOCOMPLETE_ERROR', { error }),
  success: (queryPassed) => createAction('AUTOCOMPLETE_SUCCESS', { queryPassed })
};

export const recommendations = {
  recommendationsStart: (data) => createAction('RECOMMENDATIONS_START', { data }),
  recommendationsFail: (error) => createAction('RECOMMENDATIONS_ERROR', { error }),
  recommendationsSuccess: (data) => createAction('RECOMMENDATIONS_SUCCESS', { data })
};

export const advertisementSearch = {
  advStart: (data) => createAction('ADVERTISEMENT_START', { data }),
  advFail: (error) => createAction('ADVERTISEMENT_ERROR', { error }),
  advSuccess: (data) => createAction('ADVERTISEMENT_SUCCESS', { data })
};
export const suGPTSearch = {
  start: (data) => createAction('SUGPT_START', { data }),
  fail: (error) => createAction('SUGPT_ERROR', { error }),
  success: (data) => createAction('SUGPT_SUCCESS', { data })
};

export const pageRating = {
  start: (queryPassed) => createAction('PAGERATING_START', { queryPassed }),
  fail: (error) => createAction('PAGERATING_ERROR', { error }),
  success: (queryPassed) => createAction('PAGERATING_SUCCESS', { queryPassed })
};

export const facetPreferenceCall = {
  start: (queryPassed) => createAction('FACETPERFERENCE_START', { queryPassed }),
  fail: (error) => createAction('FACETPERFERENCE_ERROR', { error }),
  success: (queryPassed) => createAction('FACETPERFERENCE_SUCCESS', { queryPassed })
};

export default createReducer(initialState, {
  [search.start().type]: (state) => {
    start++;
    if (variables.facetSearch) {
      filterSearch = false;
    } else {
      filterSearch = true;
    }
    return {
      ...state,
      inProgress: filterSearch,
      adHtml: ''
    };
  },
  [search.success().type]: (state, { queryPassed }) => {
    start--;
    if (variables.facetSearchCheck.length && variables.searchCallVariables.aggregations.length) {
      const hashMap = new Map();
      variables.searchCallVariables.aggregations.forEach((value) => {
        hashMap.set(value.key, value);
      });
      queryPassed.aggregationsArray.forEach((element) => {
        const value = hashMap.get(element.key);
        if (value) {
          element.values.forEach((item) => {
            let name = item.Contentname || item.displayName;
            if (item.selected && variables.facetSearchCheck.includes(name)) {
              let backup = element.values[element.values.indexOf(item)];
              element.values.splice(element.values.indexOf(item), 1);
              element.values.unshift(backup);
            }
          });
        }
      });
    }
    variables.facetSearch = false;
    if (queryPassed?.searchClientSettings?.autoCompleteInstant) {
      val = Boolean(start);
    } else {
      val = false;
    }

    return {
      ...state,
      inProgress: val,
      searchResult: queryPassed
    };
  },
  [autocomplete.start().type]: (state) => ({
    ...state
  }),
  [autocomplete.success().type]: (state, { queryPassed }) => ({
    ...state,
    autocomplete: queryPassed
  }),
  [suGPTSearch.start().type]: (state) => ({
    ...state
  }),
  [suGPTSearch.success().type]: (state, { data }) => ({
    ...state,
    suGPTStream: data
  }),
  [advertisementSearch.advStart().type]: (state) => ({
    ...state
  }),
  [advertisementSearch.advSuccess().type]: (state, { data }) => ({
    ...state,
    adHtml: data
  }),
  [recommendations.recommendationsStart().type]: (state) => ({
    ...state
  }),
  [recommendations.recommendationsSuccess().type]: (state, { data }) => ({
    ...state,
    recommendationsResults: data
  }),
  [pageRating.start().type]: (state) => ({
    ...state
  }),
  [pageRating.success().type]: (state, { queryPassed }) => ({
    ...state,
    pageRatingResult: queryPassed
  }),
  [facetPreferenceCall.start().type]: (state) => ({
    ...state
  }),
  [facetPreferenceCall.success().type]: (state, { queryPassed }) => ({
    ...state,
    facetPreferenceResult: queryPassed
  })
});
