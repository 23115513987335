/* eslint-disable no-undef */
/* eslint-disable no-undef */

let sid =
  typeof _gr_utility_functions !== `undefined` ? _gr_utility_functions.getCookie('_gz_taid') : '';

let variables = {
  searchSource: '',
  facetSearch: false,
  hasError: false,
  STREAM_DELIMITER: '$___$__$_$',
  ///searchClientType: '6',
  sortingOrder: scConfiguration.default_results_sorting,
  searchClientType: scConfiguration.searchClientTypeId,
  searchCallVariables: {
    langAttr: '',
    react: 1,
    isRecommendationsWidget: false,
    searchString: '',
    from: 0,
    sortby: '_score',
    orderBy: 'desc',
    pageNo: 1,
    aggregations: [],
    clonedAggregations: [],
    //uid : '58a1f4e7-3b18-11ed-9ea7-0242ac120009',
    uid: scConfiguration.uid,
    resultsPerPage: 10,
    exactPhrase: '',
    withOneOrMore: '',
    withoutTheWords: '',
    pageSize: 10,
    sid: sid,
    language: '',
    mergeSources: false,
    versionResults: true,
    showMoreSummary: false,
    minSummaryLength: false,
    suCaseCreate: false,
    visitedtitle: '',
    paginationClicked: false,
    email: window.su_utm || ''
  },
  autocompleteCallVariables: {
    autocomplete: true,
    react: 1,
    searchString: '',
    from: 0,
    sortby: '_score',
    orderBy: 'desc',
    pageNo: 1,
    aggregations: [],
    resultsPerPage: 10,
    exactPhrase: '',
    withOneOrMore: '',
    withoutTheWords: '',
    pageSize: 10,
    sid: sid,
    language: 'en',
    mergeSources: false,
    versionResults: false,
    smartFacetsClicked: false,
    smartFacets:
      typeof _gr_utility_functions !== `undefined`
        ? _gr_utility_functions.getCookie('smartFacets') == '' ||
          _gr_utility_functions.getCookie('smartFacets') == 'true'
        : '', // Add for searchbox only
    email: window.su_utm || ''
  },
  userDefinedAutoCompleteSearchUrl: {
    url: '',
    req: { method: 'POST', body: '', headers: { 'Content-Type': 'application/json' } }
  },
  controllingVariables: {
    firstTimeLoad: true,
    processing: false,
    urlState: 0,
    currentUrlState: 0
  },
  toggleDisplayKeys: [
    { key: 'Title', hideEye: false },
    { key: 'Summary', hideEye: false },
    { key: 'Url', hideEye: false },
    { key: 'Metadata', hideEye: false },
    { key: 'Icon', hideEye: false },
    { key: 'Tag', hideEye: false }
  ],
  keepAutoCompleteResultOpenOnScroll: false,
  previousSearches: [],
  hiddenFilters: [],
  storeHiddenFilters: [],
  allSelected: true,
  facetSearchCheck: [],
  selectedStickyFilter: [],
  searchAnalyticsObject: null,
  isFreshSearch: true,
  searchResposeTimer: 0,
  currentClickedOrder: null,
  activeType: 'all',
  filtersInAggr: null,
  resultsInAllContentSources: false,
  searchResultClicked: false,
  visitedtitle: '',
  visitedUrl: '',
  visiteRank: '',
  getUserEmailId: ''
};

variables.searchClientProps = {
  instanceName: scConfiguration.search,
  // instanceName: 'https://integration.searchunify.com/',
  searchEndpoint: '/search/searchResultByPost',
  adEndpoint: '/admin/searchClient/readAdHTML/' + variables.searchCallVariables.uid + '?phrase=',
  gptEndPoint: '/mlService/su-gpt'
};

variables.autocompleteCallVariables.uid = variables.searchCallVariables.uid;

//specific params according to the search client.
if (
  variables.searchClientType == 1 ||
  variables.searchClientType == 6 ||
  variables.searchClientType == 10 ||
  variables.searchClientType == 11 ||
  variables.searchClientType == 12 ||
  variables.searchClientType == 16 ||
  variables.searchClientType == 19 ||
  variables.searchClientType == 25 ||
  variables.searchClientType == 26 ||
  variables.searchClientType == 28
) {
  variables.searchCallVariables['accessToken'] = scConfiguration.accessToken;
  variables.autocompleteCallVariables['accessToken'] = scConfiguration.accessToken;
}

if (variables.searchClientType == 9 || variables.searchClientType == 3) {
  variables.searchCallVariables['email'] = window.user;
  variables.autocompleteCallVariables['email'] = window.user;
}

if (variables.searchClientType == 18) {
  delete variables.searchCallVariables['uid'];
  delete variables.autocompleteCallVariables['uid'];
}

variables.pagerating = {
  instanceName: variables.searchClientProps.instanceName,
  pageratingEndpoint: '/pageRating/getPageRatingData',
  pageratingInstanceEndpoint: '/pageRating/getPageRatingDataInstance'
};
variables.pageratingVariable = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    uid: variables.searchCallVariables.uid,
    _csrf: localStorage.getItem('_csrf')
  })
};

// Define getSid before using it
const getSid = async () => {
  return typeof _gr_utility_functions !== 'undefined'
    ? await _gr_utility_functions.getExtensionCookie('_gz_taid')
    : '';
};

if (window.location.protocol === 'chrome-extension:' && variables.searchClientType == 31) {
  getSid()
    .then((sid) => {
      variables.searchCallVariables.sid = sid;
      variables.autocompleteCallVariables.sid = sid;
    })
    .catch((error) => {
      console.error('Error getting SID:', error);
    });
}

export default variables;
