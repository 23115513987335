/* global gza */
import variables from './../redux/variables';
const getPayloadWithWildcard = () => {
  let payload;
  let wildCardSearch = !!sessionStorage.getItem('toggleWildcardSearch');
  if (wildCardSearch && variables.searchCallVariables.searchString.charAt(0) !== '#') {
    // if session storage is true
    payload = '#' + variables.searchCallVariables.searchString;
  } else {
    payload = variables.searchCallVariables.searchString;
  }
  return payload;
};
/**
 * This function calls when there is empty search string and checks for search client type zendesk console
 * if search string is empty then case subject will be tracked in analytics taking care of exact phrase as well
 * @param null
 * @returns payload
 */
const getPayloadWithCaseSubject = () => {
  let payload;
  if (
    variables.searchClientType == 16 &&
    variables.searchCallVariables.searchString == '' &&
    variables.searchCallVariables.exactPhrase == ''
  ) {
    payload = window.caseSubject;
  } else if (
    variables.searchClientType == 16 &&
    variables.searchCallVariables.searchString == '' &&
    variables.searchCallVariables.exactPhrase != ''
  ) {
    payload = variables.searchCallVariables.exactPhrase;
  } else {
    payload = variables.searchCallVariables.exactPhrase;
  }
  return payload;
};
const searchtrack = (searchResult) => {
  try {
    let filterAggregations = variables.searchCallVariables.aggregations;
    if (filterAggregations.length) {
      filterAggregations = filterAggregations.filter(function (filter) {
        return filter.filter;
      });
    }
    if (
      (variables.searchCallVariables.searchString.trim() !== '' ||
        variables.searchCallVariables.exactPhrase.trim() !== '' ||
        variables.searchClientType === 16) &&
      !variables.searchCallVariables.suCaseCreate
    ) {
      variables.searchAnalyticsObject = null;

      gza('search', {
        searchString: variables.searchCallVariables.searchString
          ? getPayloadWithWildcard()
          : getPayloadWithCaseSubject(),
        result_count: searchResult.result.total,
        page_no: variables.searchCallVariables.pageNo,
        uid: variables.searchCallVariables.uid,
        isFreshSearch: [
          'searchbox',
          'advance-search',
          'listBookmark',
          'similar-search',
          'recent-search',
          'did-you-mean'
        ].includes(variables.searchSource),
        filter: filterAggregations,
        exactPhrase: variables.searchCallVariables.exactPhrase,
        withOneOrMore: variables.searchCallVariables.withOneOrMore,
        withoutTheWords: variables.searchCallVariables.withoutTheWords,
        dym: variables.dymClicked ? variables?.previousDymSearchString : undefined,
        responseTime: variables.searchResposeTimer
      });
      variables.isFreshSearch = false;
    } else if (['searchbox'].includes(variables.searchSource)) {
      variables.searchAnalyticsObject = {
        searchString: searchResult.searchQuery,
        result_count: searchResult.result.hits.length,
        page_no: variables.searchCallVariables.pageNo,
        uid: variables.searchCallVariables.uid,
        filter: filterAggregations,
        exactPhrase: variables.searchCallVariables.exactPhrase,
        withOneOrMore: variables.searchCallVariables.withOneOrMore,
        withoutTheWords: variables.searchCallVariables.withoutTheWords,
        dym: variables.dymClicked ? variables?.previousDymSearchString : undefined
      };
    }
  } catch (e) {
    console.log('Error in searchtrack', e);
  }
};

export default searchtrack;
