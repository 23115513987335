import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const AutoCompleteLoading = (props) => {
  try {
    let { isResultExist, searchString } = props;
    return (
      !isResultExist &&
      searchString &&
      searchString.trim() !== '' && (
        <div className="su__linear-loader">
          <LinearProgress />
        </div>
      )
    );
  } catch (e) {
    console.log('Error in AutoCompleteLoading component', e);
    return <div></div>;
  }
};

export default React.memo(AutoCompleteLoading);
