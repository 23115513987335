/* global gdn, _gr_utility_functions */
import variables from '../variables';

const utilityMethods = {
  getQueryStringParams: (query) => {
    if (query) {
      if (/^[?#]/.test(query)) query = query.slice(1);
      return query.split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {});
    } else return {};
  },

  /**
   * withInterval - returns a setInterval wrapped on the input function
   * @param {function} fn - input function
   * @param {number} t - time in ms
   * @param {array} args - function arguments ( if any )
   * @returns {NodeJS.setInterval}
   */
  withInterval: (fn, t, args = []) => {
    return setInterval(() => fn(...args), t);
  },

  /**
   * withTimeout - returns a setTimeout wrapped on the input function
   * @param {function} fn - input function
   * @param {number} t - time in ms
   * @param {array} args - function arguments ( if any )
   * @returns {NodeJS.setTimeout}
   */
  withTimeout: (fn, t, args = []) => {
    return setTimeout(() => fn(...args), t);
  },

  /**
   * vanillaAuthUpdater - updates role token
   * @returns {NodeJS.setInterval} - interval
   */
  vanillaAuthUpdater: () => {
    return new Promise((resolve) => {
      variables.searchCallVariables['UserId'] = gdn?.meta?.ui?.currentUser?.userID;
      variables.searchCallVariables['roleToken'] = gdn?.meta?.roleToken;
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${window.location.origin}/api/v2/tokens/roles`, false);
      xhr.onreadystatechange = function () {
        const response = JSON.parse(xhr.response);
        if (response.roleToken) {
          variables.searchCallVariables['roleToken'] = response.roleToken;
        }
        resolve(!!response.roleToken);
      };
      xhr.send();
    });
  },

  /**
   * clearIntervalsAndTimeouts - calls clearInterval and clearTimeout on memory refs
   * @param {array} arr - array of memory refs
   * @returns {void}
   */
  clearIntervalsAndTimeouts: (arr) => {
    arr.forEach((memoryRef) => {
      clearInterval(memoryRef);
      clearTimeout(memoryRef);
    });
  },

  updateURLParams: () => {
    let paramsString = `searchString=${encodeURIComponent(
      variables.searchCallVariables.searchString
    )}&activeType=${variables.activeType}&from=${variables.searchCallVariables.from}&sortby=${
      variables.searchCallVariables.sortby
    }&orderBy=${variables.searchCallVariables.orderBy}&pageNo=${
      variables.searchCallVariables.pageNo
    }&aggregations=${encodeURIComponent(
      JSON.stringify(variables.searchCallVariables.aggregations)
    )}&uid=${variables.searchCallVariables.uid}&resultsPerPage=${
      variables.searchCallVariables.resultsPerPage
    }&exactPhrase=${encodeURIComponent(
      variables.searchCallVariables.exactPhrase
    )}&withOneOrMore=${encodeURIComponent(
      variables.searchCallVariables.withOneOrMore
    )}&withoutTheWords=${encodeURIComponent(
      variables.searchCallVariables.withoutTheWords
    )}&pageSize=${variables.searchCallVariables.pageSize}&language=${
      variables.searchCallVariables.language
    }&suCaseCreate=${variables.searchCallVariables.suCaseCreate}`;
    if (variables.searchClientType == 16) {
      paramsString = window.zeneskAppUrl || '' + paramsString;
    }
    let searchParams = new URLSearchParams(paramsString);
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
  },

  importQueryParamsToVariables: () => {
    let c = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
    let filterValue;
    if (c?.contentName != 'all') variables.allSelected = false;
    let queryParams = utilityMethods.getQueryStringParams(window.location.href.split('?')[1]);
    if (
      variables.searchClientType == 31 &&
      variables.controllingVariables.firstTimeLoad &&
      !('aggregations' in queryParams) &&
      !('searchString' in queryParams) &&
      Object.keys(queryParams).length !== 0
    ) {
      queryParams = window.location.href.split('?')[1];
      queryParams = decodeURIComponent(queryParams);
      queryParams = JSON.parse(queryParams);
    }
    variables.searchCallVariables.searchString =
      variables.searchClientType == 16
        ? queryParams.searchString || variables.searchCallVariables.searchString
        : queryParams.searchString || '';
    variables.searchCallVariables.from = queryParams.from ? JSON.parse(queryParams.from) : 0;
    let defaultDropdownValue = '_score';
    if (
      variables.controllingVariables.firstTimeLoad &&
      !variables.controllingVariables.processing &&
      window.scConfiguration &&
      window.scConfiguration.default_results_sorting
    ) {
      let parsedSortingData = window.scConfiguration?.default_results_sorting;
      defaultDropdownValue = parsedSortingData?.sortPreference?.default || '_score';
    }
    variables.searchCallVariables.sortby = queryParams.sortby || defaultDropdownValue;
    variables.searchCallVariables.pageNo = queryParams.pageNo || 1;
    variables.activeType = queryParams.activeType || 'all';
    if (variables.controllingVariables.firstTimeLoad) {
      if (c && c['initialTab']['contentName'] != 'all') {
        if (Array.isArray(c['initialTab']['contentName'])) {
          filterValue = c['initialTab']['contentName'];
        } else {
          filterValue = [c['initialTab']['contentName']];
        }
        variables.searchCallVariables.aggregations[0] = {
          type: c['initialTab']['index'],
          filter: filterValue
        };
        variables.activeType = c['initialTab']['contentName'];
      } else {
        variables.searchCallVariables.aggregations = JSON.parse(
          decodeURIComponent(decodeURIComponent(queryParams.aggregations || '%5B%5D'))
        );
        variables.allSelected = true;
        variables.activeType = 'all';
        if (variables.searchCallVariables.aggregations.length) {
          if (
            variables.searchCallVariables.aggregations[0].type !== '_index' &&
            variables.searchCallVariables.aggregations[0].type !== '_type'
          ) {
            variables.allSelected = true;
            variables.activeType = 'all';
          } else {
            if (
              variables.searchCallVariables.aggregations[0] &&
              variables.searchCallVariables.aggregations[0].filter &&
              variables.searchCallVariables.aggregations[0].filter[0]
            ) {
              variables.allSelected = false;
              variables.activeType = variables.searchCallVariables.aggregations[0].filter[0];
            }
          }
        }
      }
    } else {
      variables.searchCallVariables.aggregations = JSON.parse(
        decodeURIComponent(decodeURIComponent(queryParams.aggregations || '%5B%5D'))
      );
      if (variables.searchCallVariables.aggregations.length) {
        if (
          variables.searchCallVariables.aggregations.find((element) => element.type == '_index')
        ) {
          variables.allSelected = false;
        }
      } else {
        variables.allSelected = true;
        variables.activeType = 'all';
      }
    }
    variables.searchCallVariables.resultsPerPage = parseInt(queryParams.resultsPerPage) || 10;
    variables.searchCallVariables.exactPhrase = queryParams.exactPhrase || '';
    variables.searchCallVariables.withOneOrMore = queryParams.withOneOrMore || '';
    variables.searchCallVariables.withoutTheWords = queryParams.withoutTheWords || '';
    variables.searchCallVariables.pageSize = queryParams.pageSize || 10;
    variables.searchCallVariables.language = queryParams.language || 'en';
    variables.searchCallVariables.suCaseCreate =
      queryParams.suCaseCreate === 'true' || queryParams.suCaseCreate === true;
    try {
      variables.searchCallVariables.smartFacets =
        (_gr_utility_functions.getCookie('smartFacets') == '' ||
          _gr_utility_functions.getCookie('smartFacets') == 'true') &&
        variables.searchCallVariables.aggregations &&
        !variables.searchCallVariables.aggregations.length &&
        localStorage.getItem('AutoLearning') == 'true';
      variables.autocompleteCallVariables.smartFacets =
        (_gr_utility_functions.getCookie('smartFacets') == '' ||
          _gr_utility_functions.getCookie('smartFacets') == 'true') &&
        variables.autocompleteCallVariables.aggregations &&
        !variables.autocompleteCallVariables.aggregations.length &&
        localStorage.getItem('AutoLearning') == 'true';
    } catch (err) {
      console.log(err);
    }
  }
};

export default utilityMethods;
